import React from 'react';

const SliderCard = ({ data }) => {
    const { name } = data;
    const { links } = data;
    const { linkName } = data;
    const { bgImg } = data;
    return (
      <div className="slider" style={{ backgroundImage: `url(${bgImg})` }}>
        <div className="slide_content">
          <div className="slide_content_wrapper mb-0 h-auto bg-dark-100">
            <div className="slide_content_inner">
              <div className="meta m-0">
                <div className="category text-olive text-uppercase">
                  Carpipop
                </div>
              </div>
              <h4>
                <a href={links} className="text-white">
                  {name}
                </a>
              </h4>
              <div className="details_link">
                <a href={links}>
                  <span className="link_text">{linkName}</span>
                  <span className="link_icon">
                    <span className="line"></span>
                    <span className="circle"></span>
                    <span className="dot"></span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default SliderCard;