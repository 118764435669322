import React from 'react';

const TestimonialSlide = () => {

    return (
      <div className="testimonial-block text-center">
        <p>
          “ He tenido la suerte de contar con
          <strong> Cristian Pop</strong> para un proyecto de renovación completa
          de mi cocina, y el resultado ha sido simplemente sorprendente. Desde
          el diseño inicial hasta la instalación final, su equipo se mostró
          profesional y comprometido en brindarme una cocina funcional y
          estéticamente impresionante. Han aprovechado al máximo el espacio
          disponible y los materiales utilizados son de la más alta calidad. Sin
          duda,
          <strong>Carpipop</strong> es mi elección número uno cuando se trata de
          trabajos de carpintería. “
        </p>
        <h6 className="text-olive">Javier Rodríguez, Decoración</h6>
        <div className="flex">
          <span>⭐️</span>

          <span>⭐️</span>

          <span>⭐️</span>

          <span>⭐️</span>

          <span>⭐️</span>
        </div>
      </div>
    );
};

export default TestimonialSlide;