import React from 'react';
import ServiceContainer03 from '../../containers/AvisoLegal';
import Contacto from "../../components/ContactForm";

const ServiceDetails = () => {
    return (
        <>
            
           
            <ServiceContainer03 />
            <Contacto />
        </>
    );
};

export default ServiceDetails;