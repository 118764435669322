import React from 'react';
import ServiceCard03 from '../components/ServiceCard03';

const ServiceContainer04 = () => {
    const ServiceData03 = [
      {
        link: "/carpinteria",
        id: "01",
        img01: "images/icon_box/2.svg",
        title: "Carpintería ",
        text: "Lo que distingue a Carpipop es su capacidad para materializar las ideas y deseos de los clientes junto con sus 23 años...",
      },
      {
        link: "/ebanisteria",
        id: "02",
        img01: "images/icon_box/1.svg",
        title: "Ebanisteria",
        text: "La ebanistería es un arte que combina habilidad técnica y creatividad para transformar la madera en piezas funcionales...",
      },
      {
        link: "/fabricacion",
        id: "03",
        img01: "images/icon_box/3.svg",
        title: "Fabricación",
        text: "Carpipop no se limita únicamente a ofrecer servicios de carpintería, sino que también se destaca como un fabricante int...",
      },
      {
        link: "/instalacion-industrial",
        id: "04",
        img01: "images/icon_box/4.svg",
        title: "Instalación industrial",
        text: "El servicio de instalación industrial de lacado y barnizado ofrecido por la empresa Carpipop es un testimonio de su comp...",
      },
      {
        link: "/restauracion",
        id: "05",
        img01: "images/icon_box/5.svg",
        title: "Restauración",
        text: "La restauración de carpintería antigua y mobiliario es una labor que requiere un equilibrio perfecto entre habilidad téc...",
      },
      {
        link: "/interiorismo",
        id: "05",
        img01: "images/icon_box/6.svg",
        title: "Interiorismo",
        text: "El servicio de interiorismo y diseño interior ofrecido por la empresa Carpipop es un testimonio de su capacidad para crear...",
      },
    ];

    return (
        <main className="wrapper">
            <section className="services inner in-service pb-0">
                <div className="container">
                    <div className="row">

                        {
                            ServiceData03.map(data =>
                                <ServiceCard03
                                    key={data.id}
                                    data={data}
                                />)
                        }

                    </div>
                </div>
            </section>

        </main>
    );
};

export default ServiceContainer04;