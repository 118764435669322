import React from 'react';
import BlockGallery from '../components/BlockGallery';

import Paragraph from '../components/Paragraph';

import WidgetTitle from '../components/WidgetTitle';

const BlogDetailsContainer = () => {
  return (
    <main className="wrapper">
      <section className="project-details bg-dark-200">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 offset-lg-2 text-start">
              <div className="project-details_inner">
                <div className="post_content">
                  <div className="post-header">
                    <h3 className="post-title text-center">
                      Renovando Historias: La Magia de la Restauración de
                      Muebles Antiguos
                    </h3>
                  </div>
                  <div className="intro">
                    <Paragraph text="Cada mueble antiguo lleva consigo una historia. Un testimonio silencioso de épocas pasadas y vivencias que han marcado generaciones." />
                  </div>
                  <div className="fulltext">
                    <BlockGallery
                      className="block-gallery mb-0"
                      img="images/blog/details/1.jpg"
                    />

                    <WidgetTitle
                      title="Introducción"
                      className="widget-title"
                    />

                    <Paragraph text="La restauración de muebles antiguos es un arte que busca devolver la vida y el esplendor a estas piezas, preservando su autenticidad y agregando un toque de renovación" />
                    <Paragraph text="En este artículo, nos adentraremos en el cautivador mundo de la restauración de muebles antiguos y cómo este proceso transforma objetos en tesoros atemporales." />

                    <WidgetTitle
                      title="Conexión con el pasado"
                      className="widget-title"
                    />

                    <Paragraph text=" La restauración de muebles antiguos es mucho más que simplemente arreglar daños. Es un acto de conexión con la historia y la artesanía de tiempos pasados. Cada grieta, rasguño y marca de desgaste cuenta una historia única." />

                    <Paragraph text="  Los restauradores trabajan con cuidado para conservar estas señales de envejecimiento mientras revitalizan la pieza en su conjunto." />

                    <WidgetTitle
                      title="La elegancia natural"
                      className="widget-title"
                    />

                    <Paragraph text="La madera agrega un toque de calidez y autenticidad que ningún otro material puede igualar. En el diseño de interiores, la carpintería permite introducir elementos naturales en el espacio, creando una conexión con la naturaleza." />

                    <Paragraph text=" Desde techos de madera con vigas expuestas hasta pisos de tablones de madera, cada superficie de madera aporta una sensación de confort y encanto que eleva la experiencia de habitar el espacio." />

                    <WidgetTitle
                      title="El arte de la restauración"
                      className="widget-title"
                    />

                    <Paragraph text="La restauración de muebles antiguos es un proceso que requiere una combinación de habilidad técnica y sensibilidad artística." />

                    <Paragraph text="Los restauradores trabajan meticulosamente para reparar daños, reemplazar piezas faltantes y restaurar acabados originales. Cada paso del proceso se lleva a cabo con atención al detalle y un profundo respeto por la autenticidad de la pieza." />

                    <WidgetTitle
                      title="Investigación y autenticidad"
                      className="widget-title"
                    />

                    <Paragraph text="Uno de los aspectos emocionantes de la restauración de muebles antiguos es la investigación que implica." />

                    <Paragraph text="Los restauradores investigan la historia de la pieza, su estilo y su época para asegurarse de que cada detalle de la restauración sea auténtico. Desde la selección de materiales hasta las técnicas de acabado, la restauración se basa en la precisión histórica." />

                    <WidgetTitle
                      title="Renovación y preservación"
                      className="widget-title"
                    />

                    <Paragraph text="La restauración no solo se trata de hacer que un mueble se vea nuevo; es sobre preservar su carácter y autenticidad. " />
                    <Paragraph
                      text="  Los restauradores utilizan técnicas que respetan la integridad de la pieza, manteniendo las marcas de envejecimiento que cuentan su historia. La restauración busca realzar la belleza original del mueble, destacando su individualidad.

"
                    />
                    <WidgetTitle
                      title="Pasión por el oficio"
                      className="widget-title"
                    />

                    <Paragraph text="La restauración de muebles antiguos es un trabajo que exige pasión y dedicación. Los restauradores se sumergen en el proceso con un profundo amor por el oficio y una apreciación por la historia que cada mueble representa." />
                    <Paragraph text=" Cada reparación, cada pulido y cada capa de acabado son realizados con el objetivo de crear un resultado que perdurará por generaciones." />
                    <WidgetTitle
                      title="La emoción de la transformación:"
                      className="widget-title"
                    />

                    <Paragraph text="Uno de los momentos más gratificantes en la restauración de muebles antiguos es la revelación final." />
                    <Paragraph text="Cuando la pieza restaurada emerge de su proceso de transformación, es como si la historia cobrara vida nuevamente. Los restauradores no solo devuelven el esplendor original, sino que también otorgan una nueva oportunidad para que la pieza continúe contando su historia en un nuevo contexto." />
                    <WidgetTitle title="Conclusión" className="widget-title" />

                    <Paragraph text="La restauración de muebles antiguos es un viaje que trasciende el tiempo y conecta generaciones. Es un tributo a la artesanía de antaño y una manifestación del amor por la autenticidad. " />
                    <Paragraph
                      text=" Cada mueble restaurado es una pieza única de arte y función, capaz de añadir un toque de historia y carácter a cualquier espacio moderno. La restauración de muebles antiguos es un recordatorio de que, a través del cuidado y la dedicación, podemos preservar y honrar las historias que residen en cada pieza."/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BlogDetailsContainer;