import React from 'react';
import BlogCard from '../components/BlogCard';

const Blogs = () => {
    return (
      <section className="blog pt-lg bg-dark-100">
        <div className="large_font">
          <h2 className="floating_element text-dark-200 d-flex justify-content-center">
            Carpipop
          </h2>
        </div>
        <div className="container">
          <div className="section-header text-center has_line">
            <h2 className="text-white">Noticias</h2>
          </div>

          <div className="row">
            <BlogCard
              img="images/blog/1.jpg"
              title="La armonía entre la madera y el diseño de interiores."
              text="El diseño de interiores es un arte que va más allá de la simple disposición de muebles y colores en un espacio."
              date="20 Octubre, 2022"
              dateTime="20-10-2022"
              postCount="01"
              link="la-armonia-entre-la-madera-y-el-diseno-de-interiores"
            />
            <BlogCard
              img="images/blog/2.jpg"
              title="Detrás del proceso de construir muebles excepcionales"
              text="En este artículo, exploraremos el fascinante viaje detrás del proceso de construir muebles excepcionales."
              date="1 Febrero, 2023"
              dateTime="01-02-2023"
              postCount="02"
              link="/el-arte-y-ciencia-detras-del-proceso-de-construir-muebles-excepcionales"
            />
            <BlogCard
              img="images/blog/3.jpg"
              title="Renovando historias: La magia de la restauración de muebles antiguos"
              text="Cada mueble antiguo lleva consigo una historia. Un testimonio silencioso de épocas pasadas y vivencias....."
              date="10 Agosto, 2023"
              dateTime="10-08-2023"
              postCount="03"
              link="/renovando-historias-y-la-lmagia-de-la-restauracion-de-muebles-antiguos"
            />
          </div>
        </div>
      </section>
    );
};

export default Blogs;