import React from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import GalleryImg from '../components/GalleryImg';
import Paragraph from '../components/Paragraph';
import PointOrder from '../components/PointOrder';
import WidgetTitle from '../components/WidgetTitle';
import PostHeader from './PostHeader';
import { Helmet } from "react-helmet";

const ServiceContainer03 = () => {

    return (
      <main className="wrapper">
        <Helmet>
          <title>
            Instalación industrial de lacado y barnizado | Carpipop - Factory
            Design{" "}
          </title>
        </Helmet>
        <section className="project-details bg-dark-200 col-sm-8 offset-sm-2">
          <div className="container">
            <div className="gallery_slider">
              <div className="swiper swiper_gallery">
                <Swiper
                  modules={[EffectFade, Navigation]}
                  effect="slide"
                  slidesPerView={1}
                  loop={true}
                  navigation={{
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                  }}
                >
                  <SwiperSlide>
                    <GalleryImg img="images/slider/industrial.webp" />
                  </SwiperSlide>
                 
                </Swiper>

                {/* <!-- Add Buttons --> */}
                <div className="swiper-navigation">
                  <div className="swiper-button-prev">
                    <i className="bi bi-arrow-left"></i>
                  </div>
                  <div className="swiper-button-next">
                    <i className="bi bi-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="project-details_inner">
                  <div className="post_content">
                    <PostHeader title="Instalación industrial de lacados y barnizados" />
                    <div className="fulltext text-start">
                      <Paragraph text="El servicio de instalación industrial de lacado y barnizado ofrecido por la empresa Carpipop es un testimonio de su compromiso con la excelencia en la transformación y protección de la madera." />

                      <WidgetTitle title="Enfoque meticulos" />
                      <Paragraph text=" Esta rama especializada de su negocio demuestra su versatilidad y experiencia en brindar soluciones integrales a sus clientes, desde la concepción del diseño hasta la etapa final de acabado." />

                      <PointOrder
                        li1="Nos tomamos el tiempo de entender las necesidades específicas de cada cliente y proyecto."
                        li2="Adaptamos nuestro proceso de lacado y barnizado para garantizar la función y durabilidad."
                        li3="Utilizamos técnicas avanzadas y materiales de alta calidad para lograr un acabado uniforme y resistente."
                      />

                      <Paragraph text="El lacado y barnizado son procesos esenciales en la industria de la carpintería, ya que no solo realzan la belleza natural de la madera, sino que también la protegen de los elementos externos, prolongando su vida útil. " />

                      <Paragraph text=" Carpipop se destaca al aplicar estos procesos tanto a proyectos individuales como a instalaciones industriales a gran escala, asegurando resultados de alta calidad y durabilidad." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
};

export default ServiceContainer03;