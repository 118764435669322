import React from 'react';

const ProjectCard09 = ({ data }) => {
    const { img } = data;

    return (
        <div className='grid-item'>
            <div className="thumb">
                <img className="item_image" src={img} alt="" />
                
            </div>
        </div>
    );
};

export default ProjectCard09;