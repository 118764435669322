import React from 'react';



import Paragraph from '../components/Paragraph';

import WidgetTitle from '../components/WidgetTitle';
import PostHeader from './PostHeader';
import { Helmet } from "react-helmet";
import Galeria from '../containers/ProjectContainers/ProjectGallery09'

const ServiceContainer03 = () => {

    return (
      <main className="wrapper">
        <Helmet>
          <title>Servicios de Carpintería | Carpipop - Factory Design </title>
        </Helmet>
        <section className="project-details bg-dark-200 col-sm-8 offset-sm-2">
          <div className="container">
            <div className="gallery_slider">
              <Galeria />
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="project-details_inner">
                  <div className="post_content">
                    <PostHeader title="Carpintería excepcional" />
                    <div className="fulltext text-start">
                      <Paragraph text="La carpintería es un oficio milenario que combina habilidades técnicas con creatividad, transformando la madera en piezas funcionales y estéticas. En este contexto, la empresa Carpipop se erige como un referente en el campo de la carpintería, ofreciendo un servicio excepcional que abarca desde la concepción de diseños personalizados hasta la fabricación meticulosa de piezas únicas." />

                      <WidgetTitle title="Nuestro aval: 23 años de experiencia" />
                      <Paragraph text="Lo que distingue a Carpipop es su capacidad para materializar las ideas y deseos de los clientes. Desde la creación de muebles a medida hasta la renovación de espacios interiores, la empresa colabora estrechamente con los clientes para transformar sus conceptos en piezas tangibles y funcionales." />

                      <Paragraph text="La versatilidad es uno de los pilares de Carpipop. La empresa no solo se dedica a la fabricación de muebles de alta calidad, sino que también ofrece soluciones de carpintería que abarcan desde instalaciones personalizadas en espacios residenciales hasta trabajos comerciales. Esto demuestra su capacidad para adaptarse a diversos contextos y cumplir con los requisitos específicos de cada proyecto." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
};

export default ServiceContainer03;