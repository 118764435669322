    import $ from 'jquery';
import React, { useEffect } from 'react';
import FunFact from '../../components/FunFactElements/FunFact';

const FunFacts = (props) => {
    const FunFactData = [
      {
        id: "1",
        contentName: "Maquetación perfecta",
        contentNumber: "Renderizada en 3D",
        img: "images/icon_box/ff1.svg",
      },
      {
        id: "2",
        contentName: "Inmejorables",
        contentNumber: "Calidad & Materiales",
        img: "images/icon_box/ff2.svg",
      },
      {
        id: "3",
        contentName: "Usabilidad",
        contentNumber: "Aprovecha el diseño",
        img: "images/icon_box/ff3.svg",
      },
      {
        id: "4",
        contentName: "Estética y Funcionalidad",
        contentNumber: "equilibrio armonioso",
        img: "images/icon_box/ff4.svg",
      },
    ];

    useEffect(() => {
        var a = 0;
        $(window).on("scroll", function () {

            var oTop = $('#funfacts').offset().top - window.innerHeight;
            if (a == 0 && $(window).scrollTop() > oTop) {
                $('.fun-number').each(function () {
                    $(this).prop('Counter', 0).animate({
                        Counter: $(this).text()
                    }, {
                        duration: 4000,
                        easing: 'swing',
                        step: function (now) {
                            $(this).text(Math.ceil(now));
                        }
                    });
                });
                a = 1;
            }
        });
    }, []);
    return (
        <section className={props.className} id="funfacts">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="funfacts_inner">
                            {
                                FunFactData.map(data =>
                                    <FunFact
                                        key={data.id}
                                        data={data}
                                    />)
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FunFacts;