import React from 'react';
import ServiceCard from '../components/ServiceCard';

const Services = () => {

    return (
      <section className="services bg-dark-100 pb-0">
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-4">
              <ServiceCard
                number="01"
                imgDark="images/icon_box/d1.svg"
                imgLight="images/icon_box/d1-light.svg"
                title="Carpintería"
                text="Lo que distingue a Carpipop es su capacidad para materializar las ideas y deseos de los clientes."
              />
            </div>
            <div className="col-lg-4">
              <ServiceCard
                number="02"
                imgDark="images/icon_box/d1.svg"
                imgLight="images/icon_box/d1-light.svg"
                title="Ebanistería"
                text="La ebanistería es un arte que combina habilidad técnica y creatividad para transformar la madera en piezas funcionales y estéticamente atractivas."
              />
            </div>
            <div className="col-lg-4">
              <ServiceCard
                number="03"
                imgDark="images/icon_box/d1.svg"
                imgLight="images/icon_box/d1-light.svg"
                title="Restauración"
                text="Mrittik Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture."
              />
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-4">
              <ServiceCard
                number="04"
                imgDark="images/icon_box/d1.svg"
                imgLight="images/icon_box/d1-light.svg"
                title="Fabricación"
                text="Mrittik Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture."
              />
            </div>
            <div className="col-lg-4">
              <ServiceCard
                number="05"
                imgDark="images/icon_box/d1.svg"
                imgLight="images/icon_box/d1-light.svg"
                title="Instalación"
                text="Mrittik Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture."
              />
            </div>
            <div className="col-lg-4">
              <ServiceCard
                number="06"
                imgDark="images/icon_box/d1.svg"
                imgLight="images/icon_box/d1-light.svg"
                title="Presupuesto"
                text="Mrittik Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture."
              />
            </div>
          </div>
        </div>
      </section>
    );
};

export default Services;