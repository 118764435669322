import React from 'react';

import ServiceContainer03 from '../../containers/PoliticaPrivacidad';


const ServiceDetails = () => {
    return (
        <>
            
          
            <ServiceContainer03 />
           
        </>
    );
};

export default ServiceDetails;