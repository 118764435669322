import React from 'react';
import PageBanner from '../../containers/PageBanner';
import ServiceContainer03 from '../../containers/Interiorismo';
import Contacto from '../../components/ContactForm'

const ServiceDetails = () => {
    return (
        <>
            <PageBanner pageTitle='Servicios' title="Interiorismo y diseño" activePage="Interiorismo y diseño" />
            <ServiceContainer03 />
            <Contacto />
        </>

    );
};

export default ServiceDetails;