import React from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import GalleryImg from '../components/GalleryImg';
import Paragraph from '../components/Paragraph';
import PointOrder from '../components/PointOrder';
import WidgetTitle from '../components/WidgetTitle';
import PostHeader from './PostHeader';
import { Helmet } from "react-helmet";

const ServiceContainer03 = () => {

    return (
      <main className="wrapper">
        <Helmet>
          <title>Servicios de Ebanistería | Carpipop - Factory Design </title>
        </Helmet>
        <section className="project-details bg-dark-200 col-sm-8 offset-sm-2">
          <div className="container">
            <div className="gallery_slider">
              <div className="swiper swiper_gallery">
                <Swiper
                  modules={[EffectFade, Navigation]}
                  effect="slide"
                  slidesPerView={1}
                  loop={true}
                  navigation={{
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                  }}
                >
                  <SwiperSlide>
                    <GalleryImg img="images/slider/carpipop-slider-2.jpeg " />
                  </SwiperSlide>
                 
                </Swiper>

                {/* <!-- Add Buttons --> */}
                <div className="swiper-navigation">
                  <div className="swiper-button-prev">
                    <i className="bi bi-arrow-left"></i>
                  </div>
                  <div className="swiper-button-next">
                    <i className="bi bi-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="project-details_inner">
                  <div className="post_content">
                    <PostHeader title="Ebanistería profesional" />
                    <div className="fulltext text-start">
                      <Paragraph text="La ebanistería es un arte que combina habilidad técnica y creatividad para transformar la madera en piezas funcionales y estéticamente atractivas. En este contexto, la empresa Carpipop se destaca como un referente en el campo de la ebanistería, ofreciendo un servicio de alta calidad y compromiso con la satisfacción del cliente." />

                      <WidgetTitle title="Enfoque personalizado" />
                      <Paragraph text="Carpipop se distingue por su enfoque personalizado. Cada proyecto de ebanistería de Carpipop comienza con una consulta exhaustiva con el cliente, donde se discuten ideas, necesidades y preferencias. Esto permite que la empresa comprenda la visión del cliente y pueda diseñar piezas que se ajusten perfectamente a su estilo y espacio." />

                      <PointOrder
                        li1="La ética y responsabilidad ambiental también son una prioridad para Carpipop."
                        li2="Empleamos prácticas de producción que minimicen el impacto ambiental."
                        li3="Enfoque personalizado, la combinación de tradición y la atención al detalle."
                      />

                      <Paragraph text="Uno de los aspectos más valorados por los clientes de Carpipop es la atención al detalle en cada proyecto. Cada junta, tallado y acabado es realizado con precisión y meticulosidad, asegurando que las piezas no solo sean estéticamente atractivas, sino también duraderas y funcionales. Nos enorgullece nuestro compromiso con la calidad, y lo reflejamos en cada creación que sale de nuestro taller." />

                      <Paragraph text="En conclusión, Carpipop sobresale como un destacado proveedor de servicios de ebanistería. Su enfoque personalizado, la combinación de tradición y modernidad, la atención al detalle y el compromiso con la calidad y la sostenibilidad los convierten en una elección confiable para aquellos que buscan muebles y piezas de madera excepcionales. Con un equipo de ebanistas apasionados y hábiles, Carpipop continúa dejando una marca duradera en el mundo de la ebanistería, pieza por pieza." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
};

export default ServiceContainer03;