import { RouterProvider } from 'react-router-dom';
import 'react-select2-wrapper/css/select2.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './App.css';
import { routes } from './Routes/Router';
import './assets/css/style.css';
import CookieConsent from "react-cookie-consent";




function App() {
  return (
    <div className="text-center">
      <CookieConsent
        acceptOnScroll={true}
        acceptOnScrollPercentage={20}
        location="bottom"
        buttonText="Acceptar cookies"
        cookieName="Carpipop-GDPR2"
        style={{ background: "#000000" }}
        buttonStyle={{
          display: "none",
          color: "#000000",
          fontSize: "13px",
          background: "#ffffff",
        }}
        expires={28}
      >
        Al seguir navegando estás dando tu consentimiento para el uso de
        cookies. 🍪
      </CookieConsent>

      <RouterProvider router={routes} />
    </div>
  );
}

export default App;
