import Isotope from 'isotope-layout';
import React, { useEffect, useState } from 'react';
import PageHeder from '../../components/PageHeder';
import Paragraph from '../../components/Paragraph';
import ProjectCard from '../../components/ProjectElements/ProjectCard';
import SectionGridLines from '../../components/SectionGridLines';
import SectionHeader from '../../components/SectionHeader';

const ProjectGallery = () => {
    const ProjectData01 = [
      {
        id: "1",
        img: "images/galeria-home/Carpipop-proyecto-1.jpg",
        details: "Arbol espiral madera",
        tag: "carpinteria interiorismo",
        name: "Carpinteria",
        link: "/carpinteria",
      },
      {
        id: "2",
        img: "images/galeria-home/Carpipop-proyecto-2.jpg",
        details: "Baño moderno madera",
        tag: "interiorismo",
        name: "Interiorismo",
        link: "/interiorismo",
      },
      {
        id: "3",
        img: "images/galeria-home/project25-3.jpg",
        details: "Revestimiento escaleras",
        tag: "carpinteria",
        name: "Carpinteria",
        link: "/carpinteria",
      },
      {
        id: "4",
        img: "images/galeria-home/projecto-20.jpg",
        details: "Restauración puerta antigua",
        tag: "restauracion",
        name: "Restauración",
        link: "/restauracion",
      },
      {
        id: "5",
        img: "images/galeria-home/projecto-2.jpg",
        details: "Sala de espera moderna",
        tag: "interiorismo",
        name: "Interiorismo",
        link: "/interiorismo",
      },
      {
        id: "6",
        img: "images/galeria-home/project19-4.jpg",
        details: "Baño moderno madera",
        tag: "ebanisteria carpinteria",
        name: "Ebanisteria",
        link: "/ebanisteria",
      },
      {
        id: "7",
        img: "images/galeria-home/project-10.jpg",
        details: "Vestidor ",
        tag: "carpinteria ebanisteria",
        name: "Ebanisteria",
        link: "/ebanisteria",
      },
      {
        id: "8",
        img: "images/galeria-home/Carpipop-proyecto-3.jpg",
        details: "Proyecto carpintería y ebanistería",
        tag: "carpinteria ebanisteria interiorismo",
        name: "Carpinteria",
        link: "/carpinteria",
      },
      {
        id: "9",
        img: "images/galeria-home/project-1-2.jpg",
        details: "Recepción academia",
        tag: "carpinteria",
        name: "Carpintería",
        link: "/carpinteria",
      },
      {
        id: "10",
        img: "images/galeria-home/projecto-173.jpg",
        details: "Techo madera",
        tag: "carpinteria ebanisteria",
        name: "Ebanistería",
        link: "/ebanisteria",
      },
    ];

    const [filterKey, setFilterKey] = useState('*')

    useEffect(() => {
        Isotope.current = new Isotope('.grid', {
            itemSelector: '.grid-item',
            percentPosition: true,
            // layoutMode: 'packery',
            // masonry: {
            //     columnWidth: '.grid-sizer'
            // },
            transformsEnabled: true,
            transitionDuration: "700ms",
            resize: true,
            fitWidth: true,
            columnWidth: '.grid-sizer',
            options: {
                layoutMode: 'masonry',
                masonry: { columnWidth: 100 },
                transitionDuration: '0.5s'
            }
        })

        // cleanup
        return () => Isotope.current.destroy()
    }, []);

    // handling filter key change
    useEffect(() => {
        filterKey === '*'
            ? Isotope.current.arrange({ filter: `*` })
            : Isotope.current.arrange({ filter: `.${filterKey}` })
    }, [filterKey]);

    const handleFilterKeyChange = key => () => setFilterKey(key)


    return (
      <section className="projects packery">
        <SectionGridLines></SectionGridLines>
        <div className="large_font">
          <SectionHeader title="Carpipop"></SectionHeader>
        </div>
        <div className="container">
          <div className="section-header text-center has_line">
            <PageHeder
              className="text-white"
              title="Galeria Proyectos"
            ></PageHeder>
            <div className="section-desc row align-items-center justify-content-center">
              <div className="col-lg-6 text-end">
                <Paragraph text="Un escaparate inspirador de la diversidad y el alcance de nuestro trabajo en el mundo del diseño, carpintería y creación de espacios."></Paragraph>
              </div>
              <div className="col-lg-6 text-start">
                <Paragraph text="Nuestra galería es un rincón visual que captura la esencia de nuestro trabajo a través de imágenes inspiradoras.  "></Paragraph>
              </div>
            </div>
          </div>

          <div className="portfolio-filters-content">
            <div className="filters-button-group">
              <button className="button" onClick={handleFilterKeyChange("*")}>
                Todo <sup className="filter-count"></sup>
              </button>
              <button
                className="button"
                onClick={handleFilterKeyChange("carpinteria")}
              >
                Carpintería <sup className="filter-count"></sup>
              </button>
              <button
                className="button"
                onClick={handleFilterKeyChange("ebanisteria")}
              >
                Ebanistería <sup className="filter-count"></sup>
              </button>

              <button
                className="button"
                onClick={handleFilterKeyChange("interiorismo")}
              >
                Interiorismo <sup className="filter-count"></sup>
              </button>
              <button
                className="button"
                onClick={handleFilterKeyChange("restauracion")}
              >
                Restauración <sup className="filter-count"></sup>
              </button>
            </div>
          </div>
          <div className="grid gutter-20 clearfix">
            <div className="grid-sizer"></div>

            {ProjectData01.map((data) => (
              <ProjectCard key={data.id} data={data} />
            ))}
          </div>
          {/* <LoadmoreBtn text='Discover All Projects' className='btn olive w-100' /> */}
        </div>
      </section>
    );
};

export default ProjectGallery;