import React from 'react';
import Image from 'react-image-webp';
import Nosotros from "./../assets/images/sobre-nosotros-1.jpg";
import Nosotroswebp from "./../assets/images/sobre-nosotros-1.webp";



const MissionContainer = () => {
    return (
      <section className="mission">
        <div className="container">
          <div className="mission_top_part">
            <div className="section-header text-center">
              <h6 className="text-white text-uppercase">Nuestra Historia</h6>
              <p className="text-gray-600">
                En <span className=" fw-bolder">Carpipop</span>, llevamos más de
                dos décadas dedicados a la artesanía de la madera.
              </p>
            </div>
            <div className="has_line"></div>
            <div className="text-center">
              <Image className="" src={Nosotros} webp={Nosotroswebp} />
            </div>
          </div>

          <div className="mission_bottom_part">
            <div className="row justify-content-center">
              <div className="col-lg-4 pe-lg-4">
                <div className="section-header">
                  <h3 className="text-white text-uppercase border-line">
                    23 años de experiencia
                  </h3>
                </div>
              </div>
              <div className="col-lg-6 ps-lg-4">
                <div className="mission_content">
                  <p>
                    El fundador y dueño de la empresa,{" "}
                    <span className="fw-bold">Cristian Pop</span>, ha sido
                    carpintero durante 23 años y ha heredado los conocimientos y
                    la destreza de sus antepasados. <br />
                  </p>
                  <p>
                    Carpipop cree en la importancia de la calidad y la atención
                    al detalle en cada proyecto que emprende. Nos enorgullece
                    crear piezas de madera exquisitas y duraderas que se
                    conviertan en parte de la vida cotidiana de nuestros
                    clientes.
                  </p>
                  <p>
                    A lo largo de los años, hemos perfeccionado nuestras
                    habilidades y técnicas, combinando la experiencia
                    tradicional con las mejores prácticas contemporáneas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default MissionContainer;