import React from 'react';
import PageBanner from '../../containers/PageBanner';
import ServiceContainer03 from '../../containers/Fabricacion';
import Contacto from '../../components/ContactForm'
const ServiceDetails = () => {
    return (
        <>
            <PageBanner pageTitle='Servicios' title="Fabricación" activePage="Fabricación propria" />
            <ServiceContainer03 />
            <Contacto />
        </>
    );
};

export default ServiceDetails;