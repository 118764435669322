import React from 'react';
import AboutContainer from '../../containers/AboutContainers/AboutContainer';
import Blogs from '../../containers/Blogs';
import ContactForm from '../../components/ContactForm';
import FunFacts from '../../containers/FunFacts/FunFacts';
import ProjectGallery from '../../containers/ProjectContainers/ProjectGallery';

import Services from '../../containers/ServiceContainer04';
import Sliders from '../../containers/SliderContainers/Sliders';
import Testimonials from '../../containers/Testimonials';

const Home01 = () => {
    return (
        <div className="wrapper">
            <Sliders />
            <Services />
            <AboutContainer />
            <FunFacts className='funfacts bg_1' />
            
            <ProjectGallery />
            <Testimonials />
            <Blogs />
            <ContactForm />
        </div>
    );
};

export default Home01;