import React from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

import ProjectCard09 from '../../components/ProjectElements/ProjectCard09';


const ProjectGallery09 = () => {
    const ProjectData01 = [
      {
        id: "1",
        img: "images/galeria-home/projecto-173.jpg",
        details: "Sunlight in the Room",
        tag: "carpinteria excepcional",
      },
      {
        id: "2",
        img: "images/galeria-home/project-10.jpg",
        details: "Find your own self invintage lake house",
        tag: "carpinteria excepcional",
      },
      {
        id: "3",
        img: "images/galeria-home/project25-3.jpg",
        details: "Find your own self invintage lake house",
        tag: "carpinteria excepcional",
      },
    ];


    return (
        <div className="projects packery">
          
           
                
            
            <div className="container">
             

                <div className="swiper swiper_gallery_2">
                    <Swiper
                        modules={[Navigation, EffectFade]}
                        effect="slide"
                        breakpoints={{
                            // 640: {
                            //     width: 640,
                            //     slidesPerView: 1,
                            // },
                            // 768: {
                            //     width: 768,
                            //     slidesPerView: 2,
                            // },
                            // 992: {
                            //     width: 920,
                            //     slidesPerView: 2,
                            // },

                            600: {
                                slidesPerView: 2,
                            },
                            992: {
                                slidesPerView: 3,
                            },
                        }}
                        spaceBetween={30}
                        loop={true}
                        navigation={{
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        }}
                    >
                        {
                            ProjectData01.map(data => <SwiperSlide> <ProjectCard09
                                key={data.id}
                                data={data}
                            /></SwiperSlide>)
                        }
                    </Swiper>

                    {/* <!-- Add Buttons --> */}
                    <div className="swiper-button-prev details_link l-dir pagination-previous"><a href="#"><span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div>
                    <div className="swiper-button-next details_link r-dir pagination-next"><a href="#"><span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div>
                </div>

            </div>
        </div>
    );
};

export default ProjectGallery09;