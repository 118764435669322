import React from 'react';
import ContactForm from '../components/ContactForm';


const ContactContainer = () => {

    return (
        <main className="wrapper">
           

            <ContactForm />
        </main>
    );
};

export default ContactContainer;