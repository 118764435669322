import React from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import GalleryImg from '../components/GalleryImg';
import Paragraph from '../components/Paragraph';
import PointOrder from '../components/PointOrder';
import WidgetTitle from '../components/WidgetTitle';
import PostHeader from './PostHeader';
import { Helmet } from "react-helmet";

const ServiceContainer03 = () => {

    return (
      <main className="wrapper">
        <Helmet>
          <title>Interiorismo y diseño | Carpipop - Factory Design </title>
        </Helmet>
        <section className="project-details bg-dark-200 col-sm-8 offset-sm-2">
          <div className="container">
            <div className="gallery_slider">
              <div className="swiper swiper_gallery">
                <Swiper
                  modules={[EffectFade, Navigation]}
                  effect="slide"
                  slidesPerView={1}
                  loop={true}
                  navigation={{
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                  }}
                >
                  <SwiperSlide>
                    <GalleryImg img="images/portfolio/details/sl-3.jpg" />
                  </SwiperSlide>
                
                </Swiper>

                {/* <!-- Add Buttons --> */}
                <div className="swiper-navigation">
                  <div className="swiper-button-prev">
                    <i className="bi bi-arrow-left"></i>
                  </div>
                  <div className="swiper-button-next">
                    <i className="bi bi-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="project-details_inner">
                  <div className="post_content">
                    <PostHeader title="Interiorismo y diseño" />
                    <div className="fulltext text-start">
                      <Paragraph text="El servicio de interiorismo y diseño interior ofrecido por la empresa Carpipop es un testimonio de su capacidad para crear ambientes cautivadores y funcionales que reflejen la personalidad y el estilo únicos de cada cliente. " />

                      <WidgetTitle title="Project Features" />
                      <Paragraph text=" Esta rama especializada de su negocio destaca su enfoque integral en la transformación de espacios, desde la conceptualización hasta la implementación, para lograr resultados estéticamente atractivos y ergonómicamente eficientes." />

                      <Paragraph text="El interiorismo y el diseño interior son mucho más que la simple selección de muebles y colores; es un arte que requiere comprensión, visión y atención a los detalles más sutiles. Carpipop destaca en esta área al abordar cada proyecto con una perspectiva holística, teniendo en cuenta tanto la funcionalidad como la estética. La empresa colabora estrechamente con los clientes para comprender sus necesidades, preferencias y objetivos para el espacio, lo que resulta en soluciones de diseño a medida que trascienden las expectativas." />

                      <Paragraph text="Una de las características definitorias del servicio de interiorismo de Carpipop es su habilidad para crear espacios únicos que cuentan historias. Cada diseño está enriquecido con elementos que reflejan la identidad y el estilo de vida de los clientes. Desde la elección de los materiales hasta la disposición de los muebles y la iluminación, Carpipop se esfuerza por crear entornos que no solo sean visualmente atractivos, sino que también cuenten una narrativa personal." />
                      <Paragraph
                        text="La creatividad y la innovación son aspectos esenciales en el enfoque de Carpipop hacia el interiorismo y el diseño interior. La empresa se mantiene al tanto de las últimas tendencias en diseño y tecnología, lo que les permite ofrecer soluciones modernas y vanguardistas. Ya sea la integración de tecnología inteligente o la adaptación de estilos contemporáneos, Carpipop tiene la versatilidad para abordar diferentes estilos y preferencias de diseño.

 "
                      />
                      <Paragraph
                        text="La habilidad de Carpipop para materializar los conceptos de diseño es evidente en la ejecución de cada proyecto. Los diseñadores de la empresa colaboran estrechamente con los expertos en carpintería y fabricación para asegurar que cada elemento del diseño sea realizado con precisión y atención al detalle. Esto garantiza que el diseño en papel se convierta en una realidad tangible que cumple con los más altos estándares de calidad.

 "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
};

export default ServiceContainer03;