import React from 'react';
import BlogDetailsContainer from '../../containers/BlogDetailsContainer3';
import PageBanner from '../../containers/PageBanner';

const BlogDetails = () => {
    return (
      <>
        <PageBanner
          pageTitle="Blog"
          title="Blog Carpipop"
          activePage="Carpipop"
        ></PageBanner>
        <BlogDetailsContainer />
      </>
    );
};

export default BlogDetails;