import React from 'react';

const TestimonialSlide = () => {

    return (
      <div className="testimonial-block text-center">
        <p>
          “ He tenido la oportunidad de trabajar con
          <strong> Carpipop</strong>, el dueño, en varios proyectos de renovación
          de muebles antiguos, y cada vez han superado mis expectativas. Su
          habilidad para restaurar y dar vida a piezas deterioradas es
          simplemente asombrosa. Han conservado la esencia original de cada
          mueble mientras les han dado un aspecto renovado y elegante. Además,
          su equipo es amable y se toma el tiempo para escuchar y entender mis
          deseos. ¡Recomiendo encarecidamente su carpintería!“
        </p>
        <h6 className="text-olive">Carlos López, Empresario</h6>
        <div className="flex">
          <span>⭐️</span>

          <span>⭐️</span>

          <span>⭐️</span>

          <span>⭐️</span>

          <span>⭐️</span>
        </div>
      </div>
    );
};

export default TestimonialSlide;