import $ from 'jquery';
import React, { useEffect } from 'react';
import SectionGridLines from '../../../components/SectionGridLines';

const Footer = () => {
    //  

    useEffect(() => {
        // Totop Button
        $('.totop a').on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, '300');
        });

        // Hide header on scroll down
        const nav = document.querySelector(".header");
        const scrollUp = "top-up";
        let lastScroll = 800;

        window.addEventListener("scroll", () => {
            const currentScroll = window.pageYOffset;
            if (currentScroll <= 800) {
                nav.classList.remove(scrollUp);
                $('.totop').removeClass('show');
                return;
            }

            if (currentScroll > lastScroll) {
                // down
                nav.classList.add(scrollUp);
                $('.totop').addClass('show');

            } else if (currentScroll < lastScroll) {
                // up
                nav.classList.remove(scrollUp);
                $('.totop').removeClass('show');
            }
            lastScroll = currentScroll;
        });

    }, []);
    return (
      <>
        <footer className="footer bg-dark-200 box_padding">
          <div className="footer_inner bg-black">
            <div className="container">
              <div className="row align-items-end">
                <div className="col-lg-4 col-md-2 col-sm-2">
                  <div className="section-header">
                    <h2>Info</h2>
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 col-sm-5">
                  <div className="communication">
                    <div className="info_body">
                      <h6>Sitio Web</h6>
                      <h5>
                        <a className="text-white" href="/">
                          www.carpipop.es
                        </a>
                      </h5>
                    </div>
                    <div className="info_body">
                      <h6>E-mail</h6>
                      <h5>
                        <a
                          className="text-white"
                          href="mailto:info@carpipop.es"
                        >
                          info@carpipop.es
                        </a>
                      </h5>
                    </div>
                    <div className="info_body">
                      <h6>Teléfono/Whatsapp</h6>
                      <h5>
                        <a className="text-white" href="tel:627461548">
                          627461548
                        </a>
                      </h5>
                    </div>
                    <div className="info_body">
                      <h6>Dirección</h6>
                      <h5>
                        C/ Badajos nº 56, Villacañas, Toledo <br />
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 col-sm-5">
                  <div className="footer_elements d-flex justify-content-center">
                    <div className="footer_elements_inner">
                      <div className="footer_logo img-fluid">
                        <a href="/" className="light_logo">
                          <img src="images/logo-light-footer.svg" alt="logo"  />
                        </a>
                      </div>
                      <div className="footer_social">
                        <ul className="social_list justify-content-center">
                          <li className="whatsapp">
                            <a href="https://w.app/Carpipop">
                              <i className="bi bi-whatsapp"></i>
                            </a>
                          </li>
                          <li className="phone">
                            <a href="tel:925560445">
                              <i className="bi bi-telephone-plus"></i>
                            </a>
                          </li>
                          <li className="phone">
                            <a href="tel:627461548">
                              <i className="bi bi-telephone"></i>
                            </a>
                          </li>
                          <li className="e-mail">
                            <a href="mailto:info@carpipop.es">
                              <i className="bi bi-envelope"></i>
                            </a>
                          </li>

                          <li className="instagram">
                            <a
                              href="https://www.instagram.com/carpipop.es/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="bi bi-instagram"></i>
                            </a>
                          </li>
                          <li className="facebook">
                            <a
                              href="https://www.facebook.com/carpipop.carpipop"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="bi bi-facebook"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="terms_condition justify-content-center d-flex">
                        <ul>
                          <li>
                            <a href="/aviso-legal">Aviso Legal</a>
                          </li>
                          <li>
                            <a href="/politica-cookies">Cookies</a>
                          </li>
                          <li>
                            <a href="/politica-privacidad">Privacidad</a>
                          </li>
                        </ul>
                      </div>
                      <div className="copyright">
                        <p>Carpipop 2023. Todos los derechos reservados</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <SectionGridLines />
          </div>
        </footer>

        <div className="totop">
          <a href="#">
            <i className="bi bi-arrow-up"></i>
          </a>
        </div>
      </>
    );
};

export default Footer;