import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import ThemeSwitcher from "./ThemeSwitcher";


const UpperHeader = () => {
    // Hide header on scroll down
    window.onscroll = function () { myFunction() }; function myFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.querySelector('header .container').classList.add('.top-up');
        } else {
            document.querySelector('header .container').classList.remove('.top-up');
        }
    }

    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };

    useEffect(() => {
        // Aside info bar
        $('.aside_open').click(function (e) {
            e.preventDefault();
            $('.aside_info_wrapper').addClass('show');
        });
        $('.aside_close').click(function (e) {
            e.preventDefault();
            $('.aside_info_wrapper').removeClass('show');
        });

        // Toggle Header Search
      

        // Sticky Header
        var header = $("header");
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();

            if (scroll >= 50) {
                header.addClass("bg-dark sticky");
            } else {
                header.removeClass("bg-dark sticky");
            }
        });
    }, []);

    return (
      <header className="header">
        <div className="container">
          <div className="header_inner d-flex align-items-center justify-content-between">
            <div className="logo">
              <a href="/" className="light_logo">
                <img src="images/logo-light.svg" alt="logo" />
              </a>
              <a href="/" className="dark_logo">
                <img src="images/logo-dark.svg" alt="logo" />
              </a>
            </div>

            <div className="mainnav d-none d-lg-block">
              <ul className="main_menu">
                <li className="menu-item ">
                  <a href="/">Inicio</a>
                </li>
                <li className="menu-item menu-item-has-children">
                  <a>Servicios</a>
                  <ul className="sub-menu">
                    <li className="menu-item">
                      <a href="/carpinteria">Carpintería</a>
                    </li>
                    <li className="menu-item">
                      <a href="/ebanisteria">Ebanistería</a>
                    </li>
                    <li className="menu-item">
                      <a href="/fabricacion">Fabricación propria</a>
                    </li>
                    <li className="menu-item">
                      <a href="/instalacion-industrial">
                        Instalación industrial
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="/restauracion">Restauración</a>
                    </li>
                    <li className="menu-item">
                      <a href="/interiorismo">Interiorismo y Diseño</a>
                    </li>
                  </ul>
                </li>
                <li className="menu-item">
                  <a href="/sobre-nosotros">Sobre Nosotros</a>
                </li>
                <li className="menu-item">
                  <a href="/noticias">Noticias</a>
                </li>
                <li className="menu-item">
                  <a href="/contacto">Contacto</a>
                </li>
              </ul>
            </div>
            <div className="header_right_part d-flex align-items-center">
              <ThemeSwitcher />
              <a
                href="tel:555555555"
                type="submit"
                className="aside_open form-control-submit"
              >
                <i className="bi bi-info-square"></i>
              </a>

              {/* Header Socials */}
              <div className="header_search">
                <a
                  href="tel:555555555"
                  type="submit"
                  className="form-control-submit"
                >
                  <i className="bi bi-telephone"></i>
                </a>
              </div>

              {/* <div id="open_search" className={isActive ? null : "active"}>
                            <form className="search_form">
                                <input type="text" name="search" className="keyword form-control" placeholder="Searchh..." />
                                <button type="submit" className="form-control-submit"><i className="bi bi-telephone"></i></button>
                            </form>
                        </div> */}

              {/* Mobile Responsive Menu Toggle Button */}
              <button type="button" className="mr_menu_toggle d-lg-none">
                <i className="bi bi-list"></i>
              </button>
            </div>
          </div>
        </div>
      </header>
    );
};

export default UpperHeader;