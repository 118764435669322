import React from 'react';
import BlockGallery from '../components/BlockGallery';

import Paragraph from '../components/Paragraph';


import WidgetTitle from '../components/WidgetTitle';

const BlogDetailsContainer = () => {
    return (
      <main className="wrapper">
        <section className="project-details bg-dark-200">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8 offset-lg-2 text-start">
                <div className="project-details_inner">
                  <div className="post_content">
                    <div className="post-header">
                      <h3 className="post-title text-center">
                        La Armonía entre la Madera y el Diseño de Interiores: Un
                        Enfoque en la Carpintería
                      </h3>
                    </div>
                    <div className="intro">
                      <Paragraph text="El diseño de interiores es un arte que va más allá de la simple disposición de muebles y colores en un espacio. Se trata de crear ambientes que reflejen la personalidad de los habitantes, que sean funcionales y estéticamente agradables. En este proceso creativo, la carpintería desempeña un papel crucial." />
                    </div>
                    <div className="fulltext">
                      <BlockGallery
                        className="block-gallery mb-0"
                        img="images/blog/details/1.jpg"
                      />

                      <WidgetTitle
                        title="Introducción"
                        className="widget-title"
                      />

                      <Paragraph text="La madera, como material noble y versátil, se convierte en una herramienta fundamental para dar vida a diseños únicos y duraderos. En este artículo, exploraremos cómo la carpintería en el diseño de interiores agrega una dimensión especial a los espacios, resaltando la belleza y la funcionalidad que solo la madera puede ofrecer." />

                      <WidgetTitle
                        title="El arte de la transformación"
                        className="widget-title"
                      />

                      <Paragraph text="La carpintería no solo implica la creación de muebles, sino también la transformación de los espacios. Desde la incorporación de paneles de madera en paredes hasta la construcción de estanterías y armarios empotrados, la carpintería aporta una personalización única a los ambientes. " />

                      <Paragraph text=" Cada pieza de madera es cuidadosamente seleccionada y trabajada para adaptarse a la estética del diseño general, creando una coherencia visual que fluye sin problemas." />

                      <WidgetTitle
                        title="La elegancia natural"
                        className="widget-title"
                      />

                      <Paragraph text="La madera agrega un toque de calidez y autenticidad que ningún otro material puede igualar. En el diseño de interiores, la carpintería permite introducir elementos naturales en el espacio, creando una conexión con la naturaleza." />

                      <Paragraph text=" Desde techos de madera con vigas expuestas hasta pisos de tablones de madera, cada superficie de madera aporta una sensación de confort y encanto que eleva la experiencia de habitar el espacio." />

                      <WidgetTitle
                        title="Piezas a medida"
                        className="widget-title"
                      />

                      <Paragraph text="Una de las ventajas más notables de la carpintería en el diseño de interiores es la posibilidad de crear piezas a medida. Los muebles y elementos diseñados a medida se ajustan perfectamente a las dimensiones y necesidades del espacio, maximizando su utilidad y funcionalidad. " />

                      <Paragraph text=" Estanterías, escritorios, mesas y camas hechas a medida se convierten en parte integral del ambiente, mejorando la fluidez y el uso del espacio." />

                      <WidgetTitle
                        title="Durabilidad y Sostenibilidad"
                        className="widget-title"
                      />

                      <Paragraph text="La madera es un material conocido por su durabilidad y sostenibilidad. En el diseño de interiores, la carpintería ofrece la oportunidad de incorporar elementos que perdurarán a lo largo del tiempo. " />

                      <Paragraph text="Los muebles y detalles de madera bien cuidados pueden mantener su belleza y funcionalidad durante décadas, lo que contribuye a un enfoque más sostenible en la decoración y el diseño." />

                      <WidgetTitle
                        title="Conclusión"
                        className="widget-title"
                      />

                      <Paragraph text="La carpintería en el diseño de interiores es una forma de arte que trasciende las tendencias y las modas pasajeras. Es una inversión en la estética, la funcionalidad y la atemporalidad de un espacio. La madera se convierte en la herramienta que agrega calidez, autenticidad y carácter a los ambientes, creando una armonía entre el diseño y la naturaleza. Ya sea a través de muebles a medida, detalles intrincados o elementos estructurales, la carpintería en el diseño de interiores es una forma de expresión que transforma los espacios en experiencias únicas y duraderas. " />

            
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
};

export default BlogDetailsContainer;