import React from 'react';
import Paragraph from '../components/Paragraph';
import WidgetTitle from '../components/WidgetTitle';
import PostHeader from './PostHeader';
import { Helmet } from "react-helmet";
import PointOrder from "../components/PointOrder02";
import PointOrder3 from "../components/PointOrder03";
import PointOrder2 from "../components/PointOrder";


const ServiceContainer03 = () => {

    return (
      <main className="wrapper">
        <Helmet>
          <title>Politica de Privacidad | Carpipop - Factory Design </title>
        </Helmet>
        <section className="project-details bg-dark-200 col-sm-6 offset-sm-3 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="project-details_inner">
                  <div className="post_content">
                    <PostHeader title="Politíca de Privacidad" />
                    <div className="fulltext text-start">
                      <WidgetTitle
                        className="mb-2"
                        title="Fecha de última actualización: 18-Ago-2023"
                      />
                      <Paragraph
                        text=" Bienvenido a Carpipop (en adelante nosotros, nuestro, o nosotros). 
                      Esta política de privacidad describe cómo recopilamos, usamos, compartimos y protegemos tus datos personales cuando visitas nuestro sitio web [https://carpipop.es] (sitio web) y los servicios relacionados."
                      />
                      <Paragraph text="Al visitar nuestro sitio web, aceptas las prácticas descritas en esta política de privacidad. Te recomendamos que leas esta política de privacidad con detenimiento antes de utilizar el sitio web o proporcionar cualquier tipo de información personal. " />

                      <WidgetTitle className="mb-2" title="1. Información que Recopilamos" />
                      <Paragraph text=" Cuando visitas nuestro Sitio, podemos recopilar ciertos datos personales de diversas formas, incluyendo:" />
                      <PointOrder
                        li1="Información que nos proporcionas directamente: Puedes proporcionarnos información personal como tu nombre, dirección de correo electrónico, y otra información similar."
                        li2="Información recopilada automáticamente: Podemos recopilar cierta información automáticamente cuando visitas nuestro sitio web, como tu dirección IP, tipo de navegador, ubicación geográfica y otros datos de uso."
                      />
                      <WidgetTitle className="mb-2" title="2. Uso de la Información" />
                      <Paragraph text="Utilizamos la información que recopilamos para diversos fines, incluyendo: " />
                      <PointOrder3
                        li1="Proporcionar y mantener nuestro sitio web."
                        li2="Personalizar y mejorar tu experiencia en el sitio web."
                        li3="Comprender y analizar cómo utilizas nuestro sitio web."
                        li4="Enviar comunicaciones promocionales o informativas."
                        li5="Cumplir con obligaciones legales."
                      />
                      <WidgetTitle className="mb-2" title="3. Compartir de la Información" />
                      <Paragraph text="Podemos compartir tu información personal con terceros en las siguientes situaciones:" />
                      <PointOrder2
                        li1="Con proveedores de servicios que realizan servicios en nuestro nombre."
                        li2="Para cumplir con obligaciones legales o reglamentarias."
                        li3="Para proteger nuestros derechos, privacidad, seguridad o propiedad, y los tuyos."
                      />
                      <WidgetTitle className="mb-2" title="4. Tus Derechos" />
                      <Paragraph text="Tienes ciertos derechos sobre tus datos personales, que pueden incluir el derecho a acceder, corregir, eliminar o restringir el uso de tus datos. También puedes retirar tu consentimiento en cualquier momento si has proporcionado previamente tu consentimiento para el procesamiento de tus datos." />
                      <WidgetTitle className="mb-2" title="5. Seguridad" />
                      <Paragraph text="Tomamos medidas razonables para proteger tu información personal de pérdida, robo y acceso no autorizado. Sin embargo, ten en cuenta que ningún método de transmisión por internet o de almacenamiento electrónico es 100% seguro." />
                      <WidgetTitle className="mb-2"
                        title="6. Cambios a esta Política de Privacidad
"
                      />
                      <Paragraph
                        text="Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. Cualquier cambio se publicará en esta página con una nueva fecha de -Última actualización-.
"
                      />
                      <Paragraph
                        text="Si tienes alguna pregunta sobre esta política de privacidad, por favor contáctanos a través de nuestro e-mail [info@carpipop.es].
"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
};

export default ServiceContainer03;