import React from 'react';
import BlockGallery from '../components/BlockGallery';

import Paragraph from '../components/Paragraph';

import WidgetTitle from '../components/WidgetTitle';

const BlogDetailsContainer = () => {
  return (
    <main className="wrapper">
      <section className="project-details bg-dark-200">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 offset-lg-2 text-start">
              <div className="project-details_inner">
                <div className="post_content">
                  <div className="post-header">
                    <h3 className="post-title text-center">
                      El arte y ciencia detrás del proceso de construir muebles
                      excepcionales
                    </h3>
                  </div>
                  <div className="intro">
                    <Paragraph text="La creación de muebles excepcionales es un proceso que va más allá de simples ensamblajes y cortes de madera. Cada pieza lleva consigo una historia de diseño, pasión y habilidad" />
                  </div>
                  <div className="fulltext">
                    <BlockGallery
                      className="block-gallery mb-0"
                      img="images/blog/details/1.jpg"
                    />

                    <WidgetTitle
                      title="Introducción"
                      className="widget-title"
                    />

                    <Paragraph text="En este artículo, exploraremos el fascinante viaje detrás del proceso de construir muebles excepcionales, desde la concepción de la idea hasta la pieza finalizada que adorna nuestros hogares y espacios." />

                    <WidgetTitle
                      title="Diseño y conceptualización"
                      className="widget-title"
                    />

                    <Paragraph text="odo mueble excepcional comienza con una idea. El proceso se inicia con el diseño y la conceptualización, donde los creadores buscan capturar la esencia de la funcionalidad y la estética que desean lograr." />

                    <Paragraph text="Cada detalle, desde las proporciones hasta los materiales, se cuida meticulosamente para asegurar que la pieza encaje perfectamente en su entorno y cumpla con su propósito." />

                    <WidgetTitle
                      title="Selección de materiales"
                      className="widget-title"
                    />

                    <Paragraph text="La elección de los materiales es esencial en la construcción de muebles excepcionales. Los artesanos consideran factores como la durabilidad, la estética y la sostenibilidad al seleccionar maderas, acabados y elementos complementarios." />

                    <Paragraph text="La combinación de materiales de alta calidad con técnicas de construcción expertas es lo que establece la base para una pieza excepcional." />

                    <WidgetTitle
                      title="Habilidad artesanal"
                      className="widget-title"
                    />

                    <Paragraph text="La construcción de muebles excepcionales es una muestra de habilidad artesanal en su máxima expresión. Cada corte, ensamblaje y acabado es llevado a cabo con precisión y atención al detalle" />

                    <Paragraph text="  Los carpinteros emplean técnicas tradicionales y modernas para crear piezas que sean visualmente atractivas y estructuralmente sólidas." />

                    <WidgetTitle title="Innovación" className="widget-title" />

                    <Paragraph text="La innovación también juega un papel clave en la creación de muebles excepcionales. Los artesanos buscan formas nuevas y creativas de combinar funcionalidad y diseño. La incorporación de soluciones inteligentes, como sistemas de almacenamiento ocultos o mecanismos de extensión, agrega un nivel adicional de utilidad y valor a la pieza." />

                    <WidgetTitle
                      title="Ensamblaje y acabado"
                      className="widget-title"
                    />

                    <Paragraph text="El proceso de construcción culmina en el ensamblaje y el acabado. Cada componente de la pieza se une cuidadosamente para formar un conjunto armonioso." />
                    <Paragraph text="El acabado, ya sea un tono natural de madera o un acabado pintado, se aplica con precisión para resaltar la belleza de los materiales y proteger la pieza de los elementos." />

                    <WidgetTitle
                      title="Pruebas y perfeccionamiento:"
                      className="widget-title"
                    />

                    <Paragraph text="La construcción de muebles excepcionales no concluye con el ensamblaje. Cada pieza es sometida a pruebas exhaustivas para garantizar que cumpla con los estándares de calidad y funcionalidad." />
                    <Paragraph text="Cualquier ajuste necesario se realiza con dedicación para asegurar que la pieza sea excepcional en todos los aspectos." />

                    <WidgetTitle title="Conclusion" className="widget-title" />

                    <Paragraph text="El proceso de construir muebles excepcionales es un viaje que fusiona la creatividad, la destreza artesanal y la dedicación a la calidad." />
                    <Paragraph text="Cada pieza es más que un objeto funcional; es una manifestación tangible de la pasión y la visión de sus creadores. A través de cada fase del proceso, desde la conceptualización hasta el ensamblaje final, la construcción de muebles excepcionales es una expresión de arte que transforma la madera en obras maestras que perdurarán en el tiempo y en la estética de nuestros espacios." />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BlogDetailsContainer;