import React from 'react';



const ThankYou = () => {
    return (
        <>
            <div className="header-layer-bg"></div>

            <main className="wrapper">
                <section className="error-page">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-10 offset-lg-1">
                                <div className="error-content text-center">
                                    <div className="heading">¡GRACIAS!</div>
                                    <h1>Por contactact con nosotros</h1>
                                    <p>Te responderemos en el menor tiempo posible.</p>

                                   

                                    <div className="btn_group">
                                        <a href='/' className="btn black">Llevame de vuelta al inicio</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default ThankYou;