import React from "react";

const PointOrder = ({ li1, li2, li3 }) => {
  return (
    <ul className="point_order">
      {li1 && <li>{li1}</li>}
      {li2 && <li>{li2}</li>}
      {li3 && <li>{li3}</li>}
    </ul>
  );
};

export default PointOrder;
