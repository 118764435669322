import React from 'react';
import PageBanner from '../../containers/PageBanner';
import ServiceContainer03 from '../../containers/Restauracion';
import Contacto from '../../components/ContactForm'


const ServiceDetails = () => {
    return (
        <>
            <PageBanner pageTitle='Restauración' title="Restauración" activePage="Restauración" />
            <ServiceContainer03 />
            <Contacto />

        </>
    );
};

export default ServiceDetails;