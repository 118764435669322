import React from 'react';
import PageBanner from '../../containers/PageBanner';
import ServiceContainer03 from '../../containers/Ebanisteria';
import Contacto from "../../components/ContactForm";

const ServiceDetails = () => {
    return (
        <>
            <PageBanner pageTitle='Servicios' title="Ebanistería" activePage="Ebanistería" />
            <ServiceContainer03 />
            <Contacto />

        </>
    );
};

export default ServiceDetails;