import React from 'react';
import Form from './Form';
import Paragraph from './Paragraph';
import SectionGridLines from './SectionGridLines';

const ContactForm = () => {
    return (
      <section className="contact_us bg-dark-200">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div className="section-header">
                <h1 className="text-white text-uppercase mb-4">¿Hablamos?</h1>
                <Paragraph text="Si necesitas un presupuesto gratuito o simplemente necesitas asistencía con tu proyecto/idea, no dudes en ponerte en contacto con nosotros." />
              </div>
              <div className="text-start-contact col-lg-8 offset-lg-2">
                <div className="">
                  <a href="tel:666777888">
                    <div className="d-flex flex-row mb-4 align-items-center">
                      <svg
                        className="me-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="45"
                        height="45"
                        viewBox="0 0 24 24"
                        fill="currentcolor"
                      >
                        <path
                          fill="currentColor"
                          d="M8.886 7.17c.183.005.386.015.579.443c.128.285.343.81.519 1.238c.137.333.249.607.277.663c.064.128.104.275.02.448l-.028.058a1.43 1.43 0 0 1-.23.37a9.386 9.386 0 0 0-.143.17c-.085.104-.17.206-.242.278c-.129.128-.262.266-.114.522c.149.256.668 1.098 1.435 1.777a6.634 6.634 0 0 0 1.903 1.2c.07.03.127.055.17.076c.257.128.41.108.558-.064c.149-.173.643-.749.817-1.005c.168-.256.34-.216.578-.128c.238.089 1.504.71 1.761.837l.143.07c.179.085.3.144.352.23c.064.109.064.62-.148 1.222c-.218.6-1.267 1.176-1.742 1.22l-.135.016c-.436.052-.988.12-2.956-.655c-2.426-.954-4.027-3.32-4.35-3.799a2.768 2.768 0 0 0-.053-.076l-.006-.008c-.147-.197-1.048-1.402-1.048-2.646c0-1.19.587-1.81.854-2.092l.047-.05a.95.95 0 0 1 .687-.32c.173 0 .347 0 .495.005Z"
                        />
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M2.184 21.331a.4.4 0 0 0 .487.494l4.607-1.204a10 10 0 0 0 4.76 1.207h.004c5.486 0 9.958-4.447 9.958-9.912a9.828 9.828 0 0 0-2.914-7.011A9.917 9.917 0 0 0 12.042 2c-5.486 0-9.958 4.446-9.958 9.911c0 1.739.458 3.447 1.33 4.954l-1.23 4.466Zm2.677-4.068a1.5 1.5 0 0 0-.148-1.15a8.377 8.377 0 0 1-1.129-4.202c0-4.63 3.793-8.411 8.458-8.411c2.27 0 4.388.877 5.986 2.468a8.328 8.328 0 0 1 2.472 5.948c0 4.63-3.793 8.412-8.458 8.412h-.005a8.5 8.5 0 0 1-4.044-1.026a1.5 1.5 0 0 0-1.094-.132l-2.762.721l.724-2.628Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                      <h5 className="fw-light">WhatsApp</h5>
                    </div>
                  </a>
                </div>

                <div className="">
                  <a href="tel:666777888">
                    <div className="d-flex flex-row mb-4 align-items-center">
                      <svg
                        className="me-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="45"
                        height="45"
                        viewBox="0 0 256 256"
                        fill="currentcolor"
                      >
                        <path
                          fill="currentColor"
                          d="M128 82a46 46 0 1 0 46 46a46.06 46.06 0 0 0-46-46Zm0 80a34 34 0 1 1 34-34a34 34 0 0 1-34 34Zm48-136H80a54.06 54.06 0 0 0-54 54v96a54.06 54.06 0 0 0 54 54h96a54.06 54.06 0 0 0 54-54V80a54.06 54.06 0 0 0-54-54Zm42 150a42 42 0 0 1-42 42H80a42 42 0 0 1-42-42V80a42 42 0 0 1 42-42h96a42 42 0 0 1 42 42ZM190 76a10 10 0 1 1-10-10a10 10 0 0 1 10 10Z"
                        />
                      </svg>
                      <h5 className="fw-light">Instagram</h5>
                    </div>
                  </a>
                </div>
                <div className="">
                  <a href="tel:666777888">
                    <div className="d-flex flex-row mb-4 align-items-center">
                      <svg
                        className="me-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="45"
                        height="45"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="currentColor"
                          d="M224 50H32a6 6 0 0 0-6 6v136a14 14 0 0 0 14 14h176a14 14 0 0 0 14-14V56a6 6 0 0 0-6-6Zm-15.42 12L128 135.86L47.42 62ZM216 194H40a2 2 0 0 1-2-2V69.64l86 78.78a6 6 0 0 0 8.1 0L218 69.64V192a2 2 0 0 1-2 2Z"
                        />
                      </svg>
                      <h5 className="fw-light">Correo Electronico</h5>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <Form />
            </div>
          </div>
        </div>

        <SectionGridLines />
      </section>
    );
};

export default ContactForm;