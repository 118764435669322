import React from 'react';






function Form() {
   const [result, setResult] = React.useState("");

   const onSubmit = async (event) => {
     event.preventDefault();
     setResult("Sending....");
     const formData = new FormData(event.target);

     formData.append("access_key", "61e4ce35-0168-4394-bffd-91b87cc2a685");

     const res = await fetch("https://api.web3forms.com/submit", {
       method: "POST",
       body: formData,
     }).then((res) => res.json());

     if (res.success) {
       console.log("Success", res);
       setResult(res.message);
     } else {
       console.log("Error", res);
       setResult(res.message);
     }
   };

  return (
    <div className="home_contact">
      <form onSubmit={onSubmit}>
       
        <input
          className="form-control form-control-lg"
          name="name"
          id="name"
          type="text"
          placeholder="Nombre *"
          required
          aria-label=".form-control-lg example"
        />
        <input
          className="form-control form-control-lg"
          name="phone"
          id="phone"
          type="number"
          placeholder="Nº Telefóno *"
          aria-label=".form-control-lg example"
        />
        <input
          className="form-control form-control-lg"
          name="email"
          id="email"
          type="email"
          placeholder="E-mail *"
          required
          aria-label=".form-control-lg example"
        />
        <textarea
          className="form-control pt-4"
          name="message"
          id="message"
          placeholder="Consulta"
          rows="3"
        ></textarea>
        <div className="btn_group">
          <button type="submit" className="btn olive">
            Enviar Consulta
          </button>
        </div>
      </form>
      <span>{result}</span>
    </div>
  );
}

export default Form;