
const ServiceCard03 = ({ data }) => {
    const { img01, title, text, link } = data;
    return (
      <div className="col-lg-4">
        <a href={link}>
          <div class="icon_box">
            <div className="icon_box_inner">
              <img src={img01} alt="Icon Box" />
              <h4 className="text-white">
                <a href={link}>{title}</a>
              </h4>
              <p className="text-gray-600">{text}</p>
              <div className="arrow_effect align-items-center">
                <a href={link}>
                  <span className="crossline1"></span>
                  <span className="crossline2"></span>
                </a>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
};

export default ServiceCard03;