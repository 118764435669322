import React from 'react';

const AsideInfo = () => {
    return (
      <div className="aside_info_wrapper">
        <button className="aside_close">
          <i className="bi bi-x-lg"></i>
        </button>
        <div className="aside_logo">
          <a href="/" className="light_logo">
            <img src="images/logo-light-lg.svg" alt="logo" />
          </a>
          <a href="/" className="dark_logo">
            <img src="images/logo-dark-lg_copia.svg" alt="logo" />
          </a>
        </div>
        <div className="aside_info_inner">
          <p>
            Bienvenido a <strong>Carpipop | Factory Design.</strong>
            <br /> Servicios de Carpintería completos.
          </p>

          <div className="aside_info_inner_box">
            <h5>Contacto</h5>
            <p>
              <a href="tel:627461548">
                <i className="bi bi-telephone"> 627 461 548</i>
              </a>
            </p>

            <p>
              {" "}
              <a href="mailto:info@carpipop.es">
                <i className="bi bi-envelope"> info@carpipop.es</i>
              </a>
            </p>

            <h5>Dirección</h5>
            <p>
              C/ Badajos nº 56 <br /> Villacañas, Toledo
            </p>
          </div>
          <div className="social_sites">
            <h5>Social Media</h5>
            <ul className="d-flex align-items-center justify-content-center">
              <li className="whatsapp">
                <a href="https://w.app/Carpipop">
                  <i className="bi bi-whatsapp"></i>
                </a>
              </li>
              <li className="phone">
                <a href="tel:925560445">
                  <i className="bi bi-telephone-plus"></i>
                </a>
              </li>
              <li className="phone">
                <a href="tel:627461548">
                  <i className="bi bi-telephone"></i>
                </a>
              </li>
              <li className="e-mail">
                <a href="mailto:info@carpipop.es">
                  <i className="bi bi-envelope"></i>
                </a>
              </li>

              <li className="instagram">
                <a
                  href="https://www.instagram.com/carpipop.es/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-instagram"></i>
                </a>
              </li>
              <li className="facebook">
                <a
                  href="https://www.facebook.com/carpipop.carpipop"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="bi bi-facebook"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
};

export default AsideInfo;