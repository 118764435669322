import React from 'react';
import BlogsContainer from '../../containers/Blogs';


const Blog = () => {
    return (
        <>
           
            <main className="wrapper pt-5">
                <BlogsContainer />
            </main>
        </>
    );
};

export default Blog;