import React from 'react';

const TestimonialSlide = () => {

    return (
      <div className="testimonial-block text-center">
        <p>
          “ Desde que descubrí
          <strong> Carpipop</strong>, se ha convertido en mi destino principal
          para todos mis muebles personalizados. Me encanta cómo logran combinar
          a la perfección mia diseñoa contemporáneoa con la funcionalidad. Cada
          pieza que he encargado ha sido única y se ha adaptado a mis
          necesidades y la de mis clientes. Además, la calidad de la madera
          utilizada es excepcional y los acabados son impecables. ¡Gracias por
          hacer realidad mis ideas y hacer más fácil mitrabajo."
        </p>
        <h6 className="text-olive">Laura Torres, Diseñadora</h6>
        <div className="flex">
          <span>⭐️</span>

          <span>⭐️</span>

          <span>⭐️</span>

          <span>⭐️</span>

          <span>⭐️</span>
        </div>
      </div>
    );
};

export default TestimonialSlide;