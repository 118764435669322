import React from 'react';
import IconBoxFlex from '../../components/IconBoxFlex';
import Paragraph from '../../components/Paragraph';
import SubHeading from '../../components/SubHeading';
import Image from "react-image-webp";
import Nosotros from "../../assets/images/sobre-nosotros-3.jpg";
import Nosotroswebp from "../../assets/images/sobre-nosotros-3.webp";

const AboutContainer = () => {

    return (
      <section className="about bg-dark-100">
        <div className="large_font">
          <h2 className="floating_element text-dark-200 d-flex justify-content-center">
            Carpipop
          </h2>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-5">
              <div className="about_image shadow">
                <Image src={Nosotros} webp={Nosotroswebp} />
                <img src="images/about/e1.svg" alt="About" />
              </div>
            </div>
            <div className="col-lg-7 col-md-7">
              <div className="about_text_inner">
                <div className="about_text">
                  <SubHeading title="Diseños exclusivos"></SubHeading>
                  <Paragraph text="Desde elegantes mesas de comedor hasta detallados armarios empotrados, cada proyecto es un testimonio de nuestra dedicación a la perfección."></Paragraph>
                  <Paragraph text="Nuestros servicios abarcan desde la creación de muebles a medida que se adaptan perfectamente a tus espacios, hasta la restauración cuidadosa de piezas antiguas para devolverles su esplendor original."></Paragraph>
                </div>

                <div className="about_icon_box">
                  <div className="row">
                    <div className="col-lg-6">
                      <IconBoxFlex
                        img="images/about/i2.svg"
                        text="Creatividad y originalidad"
                      ></IconBoxFlex>
                    </div>
                    <div className="col-lg-6">
                      <IconBoxFlex
                        img="images/about/i3.svg"
                        text="Calidad artesanal"
                      ></IconBoxFlex>
                    </div>
                    <div className="col-lg-6">
                      <IconBoxFlex
                        img="images/about/i1.svg"
                        text="Atención al detalle"
                      ></IconBoxFlex>
                    </div>
                    <div className="col-lg-6">
                      <IconBoxFlex
                        img="images/about/i4.svg"
                        text="Versatilidad y variedad"
                      ></IconBoxFlex>
                    </div>
                  </div>
                </div>

                <div className="btn_group">
                  <a href="/contacto" className="btn gray">
                    Contacto
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default AboutContainer;