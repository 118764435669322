import React from "react";
import Paragraph from "../components/Paragraph";
import WidgetTitle from "../components/WidgetTitle";
import PostHeader from "./PostHeader";
import { Helmet } from "react-helmet";
import PointOrder from "../components/PointOrder02";
import PointOrder3 from "../components/PointOrder03";
import PointOrder2 from "../components/PointOrder";

const ServiceContainer03 = () => {
  return (
    <main className="wrapper">
      <Helmet>
        <title>Aviso Legal | Carpipop - Factory Design </title>
      </Helmet>
      <section className="project-details bg-dark-200 col-sm-6 offset-sm-3 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="project-details_inner">
                <div className="post_content">
                  <PostHeader title="Aviso Legal" />
                  <div className="fulltext text-start">
                    <WidgetTitle
                      className="mb-2"
                      title="Fecha de última actualización: 18-Ago-2023"
                    />
                    <Paragraph
                      text=" Este aviso legal regula el uso del sitio web [https://carpipop.es] (en adelante, el Sitio). Te recomendamos leer este aviso legal detenidamente antes de utilizar el Sitio.
"
                    />

                    <WidgetTitle
                      className="mb-2"
                      title="1. Datos de Identificación"
                    />
                    <PointOrder3
                      li1="Nombre de la empresa: Carpipop | Factory Design S.L."
                      li2="NIF/CIF: [Número de identificación fiscal]"
                      li3="Dirección: [Dirección de la empresa]"
                      li4="Teléfono: [Número de teléfono]"
                      li5="Correo electrónico: [info@carpipop.es]"
                    />
                    <WidgetTitle
                      className="mb-2"
                      title="2. Propiedad Intelectual e Industrial"
                    />
                    <Paragraph
                      text="Todos los contenidos del Sitio, como textos, imágenes, logotipos, marcas, diseño y software, están protegidos por derechos de propiedad intelectual e industrial. Queda prohibida su reproducción, distribución, comunicación pública y transformación sin el consentimiento expreso del titular de los derechos.
 "
                    />

                    <WidgetTitle className="mb-2" title="3. Uso del Sitio" />
                    <Paragraph text="El acceso y uso del Sitio están sujetos a los siguientes términos y condiciones:" />
                    <PointOrder2
                      li1="El usuario utilizará el Sitio de forma lícita, respetando la legislación aplicable y los derechos de terceros.
"
                      li2="Queda prohibida la utilización del Sitio con fines ilegales, lesivos, difamatorios o que puedan dañar la imagen de Carpipop | Factory Design S.L.
"
                      li3="- El usuario será responsable de mantener la confidencialidad de los datos de acceso proporcionados para el uso del Sitio.
"
                    />
                    <WidgetTitle
                      className="mb-2"
                      title="4. Limitación de Responsabilidad"
                    />
                    <Paragraph
                      text="
El uso del Sitio se realiza bajo la responsabilidad del usuario. Carpipop | Factory Design S.L.] no se hace responsable de los daños causados por el uso del Sitio o de los contenidos proporcionados en él.
"
                    />
                    <WidgetTitle
                      className="mb-2"
                      title="5. Enlaces a Terceros
"
                    />
                    <Paragraph
                      text="El Sitio puede contener enlaces a sitios web de terceros. Carpipop | Factory Design S.L. no controla ni se hace responsable de los contenidos de estos sitios enlazados.
"
                    />
                    <WidgetTitle
                      className="mb-2"
                      title="6. Protección de Datos Personales

"
                    />
                    <Paragraph
                      text="El uso de datos personales se regirá por la política de privacidad disponible en la la política de privacidad.

"
                    />
                    <WidgetTitle
                      className="mb-2"
                      title="7. Ley Aplicable y Jurisdicción"
                    />
                    <Paragraph
                      text="Este aviso legal se rige por la legislación Española y cualquier controversia que surja en relación con el Sitio estará sujeta a los tribunales de Toledo.
.
"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ServiceContainer03;
