import React from 'react';

const TextAbout = () => {
    return (
      <section className="highlight_banner bg-dark-200">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-11 p-lg-0">
              <p className="about_para text-center">
                utilizamos únicamente
                <span>
                  <a href="/contacto"> maderas de la más alta calidad </a>
                </span>
                seleccionadas con cuidado para
                <span>
                  <a href="/contacto"> garantizar su belleza y durabilidad</a>
                </span>
              </p>
             
            </div>
          </div>
        </div>
      </section>
    );
};

export default TextAbout;