import React from 'react';
import FunFacts from '../containers/FunFacts/FunFacts';
import MissionContainer from "../containers/MissionContainer";
import ServiceContainer04 from '../containers/ServiceContainer04';
import Testimonials from '../containers/Testimonials';
import TextAbout from './TextAbout';
import { Helmet } from "react-helmet";
import ContactForm from "../components/ContactForm";

const AboutUs = () => {
   

    return (
      <main className="wrapper">
        <Helmet>
          <title>Sobre Nosotros | Carpipop Factory Design </title>
        </Helmet>
        <MissionContainer />

       

        <FunFacts className="funfacts bg_2" />
        <ServiceContainer04 />
        <TextAbout />
       
        <Testimonials />
        <ContactForm />
      </main>
    );
};

export default AboutUs;