import React from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import GalleryImg from '../components/GalleryImg';
import Paragraph from '../components/Paragraph';
import PointOrder from '../components/PointOrder';
import WidgetTitle from '../components/WidgetTitle';
import PostHeader from './PostHeader';
import { Helmet } from "react-helmet";

const ServiceContainer03 = () => {

    return (
      <main className="wrapper">
        <Helmet>
          <title>Servicio de Restauración | Carpipop - Factory Design </title>
        </Helmet>
        <section className="project-details bg-dark-200 col-sm-8 offset-sm-2">
          <div className="container">
            <div className="gallery_slider">
              <div className="swiper swiper_gallery">
                <Swiper
                  modules={[EffectFade, Navigation]}
                  effect="slide"
                  slidesPerView={1}
                  loop={true}
                  navigation={{
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                  }}
                >
                  <SwiperSlide>
                    <GalleryImg img="images/slider/restauracion.webp" />
                  </SwiperSlide>
                 
                 
                </Swiper>

                {/* <!-- Add Buttons --> */}
                <div className="swiper-navigation">
                  <div className="swiper-button-prev">
                    <i className="bi bi-arrow-left"></i>
                  </div>
                  <div className="swiper-button-next">
                    <i className="bi bi-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="project-details_inner">
                  <div className="post_content">
                    <PostHeader title="Restauración de  carpintería antigua y mobiliario" />
                    <div className="fulltext text-start">
                      <Paragraph text="El servicio de restauración de carpintería antigua y mobiliario ofrecido por la empresa Carpipop refleja su profundo respeto por la historia y la artesanía, así como su compromiso con la preservación de piezas valiosas y significativas." />
                      
                      <Paragraph text="Esta rama especializada de su negocio demuestra su experiencia en revivir y rejuvenecer elementos de madera en desuso, devolviéndoles su esplendor original y su funcionalidad perdurable." />

                      <Paragraph text="La restauración de carpintería antigua y mobiliario es una labor que requiere un equilibrio perfecto entre habilidad técnica y sensibilidad artística. " />
                      <Paragraph text="Carpipop se destaca en esta área al combinar métodos tradicionales con técnicas modernas para lograr resultados excepcionales. Cada proyecto de restauración comienza con una evaluación minuciosa de la pieza, donde se identifican los daños y se elabora un plan de acción personalizado." />
                      <Paragraph
                        text="Una de las características distintivas del servicio de restauración de Carpipop es su compromiso con la autenticidad. La empresa se esfuerza por mantener la integridad y la estética original de cada pieza, respetando la época y el estilo en el que fue creada. Esto requiere un profundo conocimiento de las técnicas y los materiales utilizados en diferentes períodos, así como la habilidad para replicarlos con precisión.

 "
                      />
                      <Paragraph text=" La habilidad artesanal y la atención al detalle son fundamentales en la restauración de carpintería antigua y mobiliario, y Carpipop se enorgullece de su enfoque meticuloso en este sentido. Cada junta, tallado y acabado es realizado con habilidad y cuidado, para asegurar que la pieza restaurada recupere su belleza original y su funcionalidad sin compromisos." />
                      <Paragraph text="La pasión por la restauración también se refleja en el compromiso de Carpipop con la durabilidad. Los profesionales de la empresa utilizan técnicas y materiales que garanticen que la pieza restaurada no solo recupere su aspecto original, sino que también sea capaz de resistir el paso del tiempo y el uso continuo." />
                      <Paragraph text="La restauración de mobiliario antiguo no solo trata de devolver la vida a piezas envejecidas, sino también de preservar su valor histórico y emocional. Carpipop comprende la importancia sentimental que muchas piezas tienen para sus dueños y se esfuerza por honrar esa conexión a través de su trabajo. Cada restauración es realizada con el máximo respeto y cuidado, para asegurar que la pieza continúe siendo un tesoro atesorado por generaciones venideras." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
};

export default ServiceContainer03;