import React from 'react';
import PageBanner from '../../containers/PageBanner';
import ServiceContainer03 from '../../containers/Carpinteria';
import Contacto from "../../components/ContactForm";

const ServiceDetails = () => {
    return (
        <>
            
            <PageBanner pageTitle='Servicios' title="Carpintería" activePage="Carpintería" />
            <ServiceContainer03 />
            <Contacto />
        </>
    );
};

export default ServiceDetails;