import React from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderCard from '../../components/SliderElements/SliderCard';
import { Autoplay } from "swiper";

const Sliders = () => {
    const SliderCardData = [
      {
        id: "24",
        bgImg: "images/slider/carpipop-slider-1.webp",
        name: "Fusión perfecta de funcionalidad y estilo",
        links: "/ebanisteria",
        linkName: "Ver Ebanistería",
      },
      {
        id: "25",
        bgImg: "images/slider/carpipop-slider-2.webp",
        name: "Creamos piezas únicas para tu hogar",
        links: "/carpinteria",
        linkName: "Ver Carpintería",
      },
      {
        id: "9",
        bgImg: "images/slider/carpipop-slider-3.webp",
        name: "Carpintería y diseño interior: Donde las ideas se hacen realidad.",
        links: "/interiorismo",
        linkName: "Ver Interiorismo",
      },
    ];

    return (
      <div className="theme_slider bg-black">
        <div className="container">
          <div
            className="swiper swiper_theme_slider"
            data-swiper-autoplay="200"
          >
            <Swiper
              modules={[Pagination, EffectFade, Navigation, Autoplay]}
              effect="slide"
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              navigation={{
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
              }}
             
            >
              {SliderCardData.map((data) => (
                <SwiperSlide>
                  <SliderCard key={data.id} data={data} />
                </SwiperSlide>
              ))}
            </Swiper>

            {/* <!-- Add Buttons --> */}
            <div className="swiper-navigation">
              <div className="swiper-button-prev">
                <i className="bi bi-arrow-left"></i>
              </div>
              <div className="swiper-button-next">
                <i className="bi bi-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Sliders;