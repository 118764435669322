import React from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import GalleryImg from '../components/GalleryImg';
import Paragraph from '../components/Paragraph';
import WidgetTitle from '../components/WidgetTitle';
import PostHeader from './PostHeader';
import { Helmet } from "react-helmet";

const ServiceContainer03 = () => {

    return (
      <main className="wrapper">
        <Helmet>
          <title>Fabricación propria | Carpipop - Factory Design </title>
        </Helmet>
        <section className="project-details bg-dark-200 col-sm-8 offset-sm-2">
          <div className="container">
            <div className="gallery_slider">
              <div className="swiper swiper_gallery">
                <Swiper
                  modules={[EffectFade, Navigation]}
                  effect="slide"
                  slidesPerView={1}
                  loop={true}
                  navigation={{
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                  }}
                >
                  <SwiperSlide>
                    <GalleryImg  img="images/slider/fabricacion.webp" />
                  </SwiperSlide>
                 
                </Swiper>

                {/* <!-- Add Buttons --> */}
                <div className="swiper-navigation">
                  <div className="swiper-button-prev">
                    <i className="bi bi-arrow-left"></i>
                  </div>
                  <div className="swiper-button-next">
                    <i className="bi bi-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="project-details_inner">
                  <div className="post_content">
                    <PostHeader title="Fabriación propria" />
                    <div className="fulltext text-start">
                      <Paragraph text="La empresa Carpipop no se limita únicamente a ofrecer servicios de carpintería, sino que también se destaca como un fabricante integral de una amplia gama de productos, materiales y muebles que abarcan desde diseños personalizados hasta piezas estándar de alta calidad. Esta versatilidad y enfoque completo hacen de Carpipop una elección confiable y líder en la industria." />

                      <WidgetTitle title="Creatividad & Inovacion" />
                      <Paragraph text="Como fabricante, Carpipop tiene la capacidad de llevar a cabo proyectos desde su concepción hasta la producción final. Esto significa que la empresa puede trabajar en colaboración con sus clientes desde el principio, ideando diseños únicos y soluciones a medida que satisfagan las necesidades específicas de cada cliente. Desde la creación de muebles a medida para hogares y espacios comerciales hasta la producción en serie de elementos estándar, Carpipop tiene la versatilidad para adaptarse a una variedad de demandas." />

                      <Paragraph text="La creatividad y la innovación son aspectos clave de la fabricación en Carpipop. No solo nos limitamos a recrear diseños convencionales, sino que también buscamos constantemente nuevas formas de combinar funcionalidad y estética. Desde muebles modernos con líneas limpias hasta piezas rústicas y encantadoras, Carpipop se adapta a diferentes estilos y preferencias de diseño." />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
};

export default ServiceContainer03;