import React from "react";
import Paragraph from "../components/Paragraph";
import WidgetTitle from "../components/WidgetTitle";
import PostHeader from "./PostHeader";
import { Helmet } from "react-helmet";
import PointOrder from "../components/PointOrder02";
import PointOrder3 from "../components/PointOrder03";
import PointOrder2 from "../components/PointOrder";

const ServiceContainer03 = () => {
  return (
    <main className="wrapper">
      <Helmet>
        <title>Política de Cookies | Carpipop - Factory Design </title>
      </Helmet>
      <section className="project-details bg-dark-200 col-sm-6 offset-sm-3 mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="project-details_inner">
                <div className="post_content">
                  <PostHeader title="Politíca de Cookies" />
                  <div className="fulltext text-start">
                    <WidgetTitle
                      className="mb-2"
                      title="Fecha de última actualización: 18-Ago-2023"
                    />
                    <Paragraph text="Este sitio web utiliza cookies para mejorar la experiencia del usuario. Al continuar navegando por este sitio, aceptas el uso de cookies de acuerdo con esta política." />

                    <WidgetTitle
                      className="mb-2"
                      title="1. Definición de Cookies"
                    />
                    <Paragraph text=" Las cookies son pequeños archivos de texto que un sitio web almacena en el navegador del usuario. Estos archivos contienen información que se utiliza para mejorar la experiencia de navegación, personalizar el contenido y analizar el comportamiento de los usuarios." />

                    <WidgetTitle
                      className="mb-2"
                      title="2. Tipos de Cookies Utilizadas"
                    />

                    <PointOrder3
                      li1="Cookies Esenciales: Estas cookies son necesarias para que el sitio funcione correctamente. No almacenan información personal y se utilizan para recordar las preferencias del usuario."
                      li2="Cookies de Rendimiento: Estas cookies recopilan información sobre cómo los usuarios interactúan con el sitio web. Esto nos ayuda a mejorar el rendimiento del sitio y a comprender qué áreas son más populares."
                      li3="Cookies Funcionales: Estas cookies permiten al sitio recordar las elecciones del usuario, como el idioma o la región, para proporcionar una experiencia más personalizada."
                      li4="Cookies de Terceros: Algunas páginas del sitio web pueden incluir contenido de terceros, como videos de YouTube o botones de redes sociales. Estos terceros pueden utilizar sus propias cookies."
                      li5="Duracion de los cookies: 28 días."
                    />
                    <WidgetTitle
                      className="mb-2"
                      title="3. Control de Cookies
"
                    />
                    <Paragraph
                      text="El usuario puede controlar y gestionar las cookies en su navegador. Puede configurar su navegador para bloquear o eliminar cookies, y también puede recibir alertas cuando se utilizan cookies. Sin embargo, ten en cuenta que bloquear o eliminar cookies puede afectar la funcionalidad y la experiencia de navegación del sitio.
"
                    />

                    <WidgetTitle
                      className="mb-2"
                      title="4. Consentimiento
"
                    />
                    <Paragraph
                      text="Al continuar utilizando este sitio web, aceptas el uso de cookies según lo establecido en esta política.
"
                    />

                    <WidgetTitle
                      className="mb-2"
                      title="5. Cambios en la Política de Cookies
"
                    />
                    <Paragraph text=" Nos reservamos el derecho de modificar esta política de cookies en cualquier momento. Cualquier cambio se publicará en esta página con una nueva fecha de -Última actualización-.
" />
                   
           
                    <Paragraph
                      text="Si tienes alguna pregunta sobre esta política de cookies, por favor contáctanos a través de nuestro e-mail [info@carpipop.es].
"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ServiceContainer03;
